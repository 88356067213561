import React from "react";
import {Link} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";

// Import images

const Section = () => {
    return (
        <React.Fragment>

            <section
                className="bg-half-50 d-table w-100 overflow-hidden"
                id="home"
            >
                <Container>
                    <Row className="align-items-center pt-5">
                        <Col lg={6} md={6}>
                            <div className="title-heading">
                                <h1 className="heading mb-3">
                                    einfach selber buchen - mit KI Unterstützung
                                </h1>
                                <p className="para-desc text-muted">
                                    Revolutionieren Sie Ihre Finanzbuchhaltung: Unsere cloudbasierte Lösung mit selbstlernender KI macht Buchhaltung einfach, zuverlässig und intelligent. Massgeschneidert für KMU, Vereine, Einzelfirmen und Privatpersonen - Ihre Finanzen, optimiert für die Zukunft.
                                </p>
                                <p className="para-desc text-muted">
                                    Überzeugen Sie sich selbst und melden Sie sich für die kostenlose Basisversion an.
                                </p>
                                <div className="mt-4 pt-2">
                                    <a
                                        href="/app-new/"
                                        className="btn btn-primary"
                                    >
                                        Jetzt registrieren und gleich loslegen
                                    </a>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="classic-saas-image position-relative">
                                <div className="bg-saas-shape position-relative">

                                    <iframe width="100%" height="315"
                                            src="https://www.youtube.com/embed/0uH6LQ70Uf0?si=31-VcZr9QWR2po7_"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Section;
