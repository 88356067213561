// React Basic and Bootstrap
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";


class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricings: [
                {
                    id: 1,
                    title: "Kostenlos",
                    price: "0",
                    duration: "Monat",
                    buttonText: "Jetzt registrieren",
                    btnLink: "/app-new/",
                    features: [
                        {title: "40 Buchungen*"},
                        {title: "5 Debitoren und Kreditoren*"},
                        {title: "5 Dokumente*"},
                        {title: "5 KI assistierte Buchungen*"},
                    ],
                },
                {
                    id: 2,
                    title: "Standard",
                    price: "9.90",
                    duration: "Monat",
                    buttonText: "Ausprobieren",
                    btnLink: "/app-new/",
                    isActive: true,
                    features: [
                        {title: "Unlimitierte Buchungen"},
                        {title: "Unlimitierte Debitoren und Kreditoren"},
                        {title: "Unlimitierte Dokumente"},
                        {title: "30 KI assistierte Buchungen*"},
                    ],
                },
                {
                    id: 3,
                    title: "Professional",
                    price: "19.90",
                    duration: "Monat **",
                    buttonText: "Ausprobieren",
                    btnLink: "/app-new/",
                    features: [
                        {title: "Unlimitierte Buchungen"},
                        {title: "Unlimitierte Debitoren und Kreditoren"},
                        {title: "Unlimitierte Dokumente"},
                        {title: "bis zu 5 Mandanten "},
                        {title: "Zugang für Treuhänder"},
                        {title: "1000 KI assistierte Buchungen*"},
                    ],
                },
            ],
            activeTab: "1",
        };

    }

    render() {
        return (
            <React.Fragment>
                {/* breadcrumb */}
                <section className="bg-half-50 bg-light d-table w-100">
                    <Container>
                        <Row className="mt-5 justify-content-center">
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h4 className="title mb-0"> Unsere Preise </h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section className="section">
                    <Container>
                        <p className="text-muted para-desc mb-0">Wir bieten faire Preise und ein kostenloses Basispaket,
                            um alle Funktionen von fibu3 unkompliziert und unverbindlich kennenzulernen. </p>

                        <Row className="align-items-center">
                            {/* pricing */}
                            {this.state.pricings.map((pricing, key) => (
                                <Col md={4} key={key} className="col-12 mt-4 pt-2">
                                    <Card
                                        className="pricing pricing-primary business-rate shadow bg-light rounded text-center border-0">
                                        {pricing.isActive && (
                                            <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                        <span className="text-center d-block shadow small h6">
                          Bestseller
                        </span>
                                            </div>
                                        )}
                                        <CardBody className="py-5">
                                            <h6
                                                className={
                                                    pricing.isActive
                                                        ? "title fw-bold text-uppercase text-primary mb-4"
                                                        : "title fw-bold text-uppercase text-primary mb-4"
                                                }
                                            >
                                                {pricing.title}
                                            </h6>
                                            <div className="d-flex justify-content-center mb-4">
                                                <span className="h4 mb-0 mt-2">CHF</span>
                                                <span className="price h1 mb-0">{pricing.price}</span>
                                                <span className="h4 align-self-end mb-1">
                          /{pricing.duration}
                        </span>
                                            </div>

                                            <ul className="list-unstyled mb-0 ps-0" style={{'textAlign': 'left'}}>
                                                {pricing.features.map((feature, key) => (
                                                    <li key={key} className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                                                        {feature.title}
                                                    </li>
                                                ))}
                                            </ul>
                                            <a
                                                href={pricing.btnLink}
                                                className="btn btn-primary mt-4"
                                            >
                                                {pricing.buttonText}
                                            </a>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <p className="text-muted para-desc mb-0">* pro Kalenderjahr und Buchhaltungsperiode</p>
                            <p className="text-muted para-desc mb-0">** weitere Mandanten und KI assistierte Buchungen auf <a href="https://www.fibu3.ch/kontakt.html">Anfrage</a></p>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Pricing;
