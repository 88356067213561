// React Basic and Bootstrap
import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";


class News extends Component {
    constructor(props) {
        super(props);

    }

    // componentDidMount() {
    //     document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    // }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                {/* breadcrumb */}
                <section className="bg-half-50 bg-light d-table w-100">
                    <Container>
                        <Row className="mt-5 justify-content-center">
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h4 className="title mb-0"> Neuigkeiten </h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section className="bg-half-50 d-table w-100">
                    <Container>
                        <Row>
                            <Col>
                                <Card className="border-0 shadow rounded overflow-hidden">
                                    <CardBody>
                                        <div className="text-center">
                                            <span className="badge bg-primary">Version</span>
                                            <h4 className="mt-3">
                                                Version 1.7
                                            </h4>

                                            <ul className="list-unstyled mt-3">
                                                <li className="list-inline-item user text-muted me-2">
                                                    <i className="mdi mdi-account"></i> fibu3
                                                </li>
                                                {" "}
                                                <li className="list-inline-item date text-muted">
                                                    <i className="mdi mdi-calendar-check"></i> 14.08.2024
                                                </li>
                                            </ul>
                                        </div>

                                        <p className="text-muted mt-3">
                                            Version 1.7 wurde released. Die neue Version von fibu3 (neues User Interface) mit KI Unterstützung ist nun für alle Kunden verfügbar.
                                            Bestehende Kunden schalten sich für die neue Version frei, können aber nach wie vor auch noch auf die bestehende fibu3 Version zugreifen.

                                        </p>

                                    </CardBody>
                                </Card>
                                <Card className="border-0 shadow rounded overflow-hidden">
                                    <CardBody>
                                        <div className="text-center">
                                            <span className="badge bg-primary">Version</span>
                                            <h4 className="mt-3">
                                                Version 1.6
                                            </h4>

                                            <ul className="list-unstyled mt-3">
                                                <li className="list-inline-item user text-muted me-2">
                                                    <i className="mdi mdi-account"></i> fibu3
                                                </li>
                                                {" "}
                                                <li className="list-inline-item date text-muted">
                                                    <i className="mdi mdi-calendar-check"></i> 29.07.2024
                                                </li>
                                            </ul>
                                        </div>

                                        <p className="text-muted mt-3">
                                            Version 1.6 wurde released. Die neue Version von fibu3 mit KI Unterstützung ist für ausgewählte Kunden verfügbar und wird bald in der Breite ausgerollt.

                                        </p>

                                    </CardBody>
                                </Card>
                                <Card className="border-0 shadow rounded overflow-hidden">
                                    <CardBody>
                                        <div className="text-center">
                                            <span className="badge bg-primary">Version</span>
                                            <h4 className="mt-3">
                                                Version 1.5
                                            </h4>

                                            <ul className="list-unstyled mt-3">
                                                <li className="list-inline-item user text-muted me-2">
                                                    <i className="mdi mdi-account"></i> fibu3
                                                </li>
                                                {" "}
                                                <li className="list-inline-item date text-muted">
                                                    <i className="mdi mdi-calendar-check"></i> 21.01.2024
                                                </li>
                                            </ul>
                                        </div>

                                        <p className="text-muted mt-3">
                                            Version 1.5.2 wurde released. fibu3 unterstützt die neuen
                                            Mehrwertsteuersätze 2024 und neu läuft die Software in der Cloud.

                                        </p>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </React.Fragment>
        );
    }
}

export default News;
