// React Basic and Bootstrap
import React from "react";
import {Container, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";

// Import images
import journalImage from "../../assets/images/fibu3_journal.png";
import aiImage from "../../assets/images/fibu3/ai.jpg";
import secureAndFairImage  from "../../assets/images/fibu3/secure.jpg";

const Start = () => {

    return (
        <React.Fragment>
            <Container className="mt-100 mt-60">
                <Row className="align-items-center">
                    <Col lg={6} md={6}>
                        <img src={journalImage} className="img-fluid shadow rounded" alt=""/>
                    </Col>

                    <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title ms-lg-5">
                            <h4 className="title mb-4">Einfach und übersichtlich</h4>
                            <ul className="list-unstyled text-muted">
                                <li className="mb-1">
                                    <p className="text-muted">Wir legen viel Wert auf eine einfache
                                        Benutzerschnittstelle. Komplizierte
                                        Menüstrukturen und versteckte Funktionen suchen Sie bei uns vergebens. Wir sind
                                        der Ansicht, dass
                                        Software Ihre tägliche Arbeit vereinfachen soll.</p>
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Journalbuchen
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Mehrwertsteuer
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Debitoren und Kreditoren
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Kundenverwaltung
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    KMU und Käfer-Kontenplan
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Übersichtliche Bilanz und Erfolgsrechnung
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Mandantenfähigkeit
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Fremdwährungen
                                </li>
                                <li className="mb-1">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                                    Buchungsdaten automatisch erkennen mit KI (limitiert)
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-100 mt-60 pb-5">
                <Row className="align-items-center">
                    <Col lg={7} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title me-lg-5">
                            <h4 className="title mb-4">KI assistiertes Buchen (beta)</h4>
                            <p className="text-muted">Dank lernfähgier künstlicher Intelligenz können Sie die Buchhaltung noch
                                effizienter
                                erledigen. Einfach Belege hochladen und die Buchungen werden automatisch erkannt. Die KI
                                lernt von Ihren bestehnden Buchungen und wird dadurch immer besser.</p>
                            <ul className="list-unstyled text-muted">


                            </ul>
                            <Link to="/anmelden.html" className="mt-3 h6 text-primary">Jetzt registrieren <i
                                className="uil uil-angle-right-b"></i></Link>
                        </div>
                    </Col>

                    <Col lg={5} md={6} className="order-1 order-md-2">
                        <img src={aiImage} alt=""  width="300px"/>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-100 mt-60 pb-5">
                <Row className="align-items-center">
                    <Col lg={7} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title me-lg-5">
                            <h4 className="title mb-4">Sicher und fair</h4>
                            <p className="text-muted">Ihre Daten sind bei uns in Sicherheit. Alle Verbindungen sind
                                verschlüsselt und es finden tägliche Backups statt. Neben der kostenlosen Basisversion
                                bieten wir für unsere professionellen Kunden eine online Buchhaltung zu einem
                                unschlagbaren Preis an. Überzeugen Sie sich von unserem Angebot.</p>
                            <ul className="list-unstyled text-muted">

                            </ul>
                            <Link to="/anmelden.html" className="mt-3 h6 text-primary">Jetzt registrieren <i
                                className="uil uil-angle-right-b"></i></Link>
                        </div>
                    </Col>

                    <Col lg={5} md={6} className="order-1 order-md-2">
                        <img src={secureAndFairImage} alt="" width="300px"/>
                    </Col>

                </Row>
            </Container>
        </React.Fragment>
    );
}

export default Start;
